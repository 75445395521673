import { SearchFilter } from "src/components/Filter/SearchFilter"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { ParadiseAppliedFilters } from "src/components/Paradise/ParadiseAppliedFilters"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import {
  Ellipsis,
  IdWrapper,
  ParadiseLayout,
  ParadiseTitle,
  PillsWrapper,
  TopWrapper,
} from "src/components/Paradise/sharedStyles"
import { useFetchParadiseUsers } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { TParadiseUser } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { isSuperAdmin } from "src/data/user/logic/accessLogic"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { useSearchParams } from "src/router/useSearchParams"
import { mColors } from "src/ui/colors"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { ColumnPopoverWrapper } from "src/ui/GridTable/useTableColumns/tableColumnStyles"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import FilterIcon from "src/ui/icons/calibrating.svg"
import InfoIcon from "src/ui/icons/info.svg"
import { Titlebar } from "src/ui/Layout/Titlebar"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

type TParadiseUsersSearchFilter = {
  user_id: string | null
  name: string | null
  email: string | null
}

const usersFilterLabel: Record<keyof TParadiseUsersSearchFilter, string> = {
  user_id: "User ID",
  email: "Email",
  name: "Name",
} as const

const LIMIT = 50

export function ParadiseUsers() {
  const { navigate } = useRouter()
  const user = useGetUser()

  const { limit, offset, setOffset } = useUrlPager({ initialLimit: LIMIT })

  const {
    searchParams: filter,
    setSearchParams: setFilter,
    sortedSearchParams,
  } = useSearchParams({
    keys: [
      {
        key: "user_id",
        type: "string",
      },
      {
        key: "name",
        type: "string",
      },
      {
        key: "email",
        type: "string",
      },
    ],
  })

  const tableColumns: TableColumn<TParadiseUser>[] = [
    {
      label: "User id",
      value: "user_id",
      disabled: true,
      columnWidth: "min-content",
      disableClickPropagation: true,
      renderCell: (data) => (
        <div>
          <IdWrapper>
            <InternalLink to={Routes.ParadiseUser.location(data.id).pathname}>
              {data.id}
            </InternalLink>
            {data.deleted && (
              <MTooltip title="Deleted">
                <InfoIcon width={12} fill={mColors.systemError} />
              </MTooltip>
            )}
          </IdWrapper>
        </div>
      ),
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.user_id ?? undefined}
            placeholder="Search by id"
            onChange={(v) => setFilter("user_id", v)}
          />
          <MText variant="bodyS">Make sure to type in the exact ID</MText>
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      label: "Email",
      value: "email",
      columnWidth: 300,
      renderCell: (data) => <Ellipsis>{data.email}</Ellipsis>,
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.email ?? undefined}
            onChange={(v) => handleFilterChange("email", v)}
            placeholder="Search by email"
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      label: "Name",
      value: "name",
      disabled: true,
      columnWidth: 300,
      renderCell: (data) => <Ellipsis>{data.full_name}</Ellipsis>,
      popoverContent: (
        <ColumnPopoverWrapper>
          <SearchFilter
            initialValue={filter.name ?? undefined}
            onChange={(v) => handleFilterChange("name", v)}
            placeholder="Search by email"
          />
        </ColumnPopoverWrapper>
      ),
      popoverIcon: FilterIcon,
    },
    {
      label: "Roles",
      value: "roles",
      columnWidth: "auto",
      renderCell: (data) => (
        <div>
          <PillsWrapper $noWrap>
            {data.roles.map((role, i) => (
              <MBadge key={i} size="x-small">
                {role}
              </MBadge>
            ))}
          </PillsWrapper>
        </div>
      ),
    },
  ]

  const fetchedUsers = useFetchParadiseUsers({
    filter: {
      limit,
      offset,
      user_id: filter.user_id || undefined,
      name: filter.name || undefined,
      email: filter.email || undefined,
      sort: "name",
      sort_by: "asc",
    },
    options: {
      keepPreviousData: true,
    },
  })

  const {
    headerElements,
    interactiveColumns,
    interactiveVisibleColumns,
    rows,
    updateColumnVisibility,
    templateColumns,
  } = useTableColumns({
    columns: tableColumns,
    data: fetchedUsers.data?.users,
    options: { localStorageKey: "minut.paradise.users.table" },
  })

  function handleFilterChange(filterKey: keyof typeof filter, value: string) {
    setOffset(0)
    setFilter(filterKey, value)
  }

  return (
    <ParadiseLayout>
      <Titlebar
        bottomMargin={spacing.S}
        title={
          <ParadiseTitle>
            Users
            {fetchedUsers.isSuccess && (
              <MBadge color="info">
                Total: {fetchedUsers.data?.total_count}
              </MBadge>
            )}
          </ParadiseTitle>
        }
      />
      <TopWrapper>
        <div>
          <ParadiseAppliedFilters
            filters={sortedSearchParams.map((f) => ({
              label: usersFilterLabel[f.key],
              value: f.value.toString(),
              onRemove: () => setFilter(f.key, null),
            }))}
          />
        </div>
        <DropdownMultiSelect
          label="Columns"
          selectedValues={interactiveVisibleColumns.map((c) => c.value)}
          options={interactiveColumns}
          onChange={({ checked, option }) => {
            updateColumnVisibility(option.value, !checked)
          }}
        />
      </TopWrapper>
      <ParadiseTable
        rows={rows ?? []}
        templateColumns={templateColumns}
        header={headerElements}
        onRowClick={(index) => {
          if (!isSuperAdmin(user.roles)) return

          if (fetchedUsers.data?.users) {
            navigate(
              Routes.ParadiseUser.location(
                // @ts-expect-error: noUncheckedIndexedAccess
                fetchedUsers.data?.users[index].user_id
              )
            )
          }
        }}
        error={{
          hasError: fetchedUsers.isError,
          title: fetchedUsers.error?.message,
        }}
      />
      <ParadisePager
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchedUsers.data?.total_count}
      />
    </ParadiseLayout>
  )
}
